$body_family   : 'Cabin','Arial';
$body_size     : 100%;
$body_line     : 1.5;
$body_weight   : 400;
$body_bg_color : #ffffff;
$body_color 	 : #181d1e;

//==============

$indent_big				: 256px;
$indent_pc				: 128px;
$indent_tab				: 64px;
$indent_mid				: 42px;
$indent_mob				:	24px;
$indent_mob_small	:	16px;


$arr_h			:( h1:300%, h2:250%, h3:150%, h4:115%, h5:100% ); //size on pc
$arr_h_tab	:( h1:255%, h2:220%, h3:130%, h4:110%, h5:100% ); //resize on tab
$arr_h_mob	:( h1:220%, h2:190%, h3:120%, h4:105%, h5:90% ); //resize on mobile
$h_font			: 'Montserrat','Arial';
$h_color		: #204289;
$h_weight		: 700;
$h_space    : normal;

//==============

$p_size  : 100%;
$p_size_mob  : 100%;
$p_line  : 1.5;
$p_line_mob  : 1.5;
$p_color : #204289;
$p_align : left;
$p_weight : 500;
$p_align_mob : justify;
$p_font : 'Cabin','Arial'; //

//==============

$a_color : rgba(0, 0, 0,0.87);

//==============

$media_pc			:1410px;
$media_mid_pc	:1279px;	//	resize on laptop
$media_tab		:959px;		//	resize on tab
$media_mob		:599px;		//	resize on mobile

//==============

$max_width 			:	1140px;
$max_width_tab 	:	92%;
$max_width_min 	: 750px;
$max_width_mid 	: 1450px;
$max_width_back : 1196px;
$max_width_nano	: 760px;

//===============

$mob_menu_width : 230px;


$mob_sec1: 400px;


//========================================================
//========================================================
//========================================================
//========================================================
//========================================================

input,textarea{
  font-family:$body_family;
}
body{
  font-family:$body_family;
  font-size:$body_size;
  line-height:$body_line;
  font-weight:$body_weight;
  /* background-color:$body_bg_color; */
 /* color:$body_color;*/
}

p{
  font-size: $p_size;
  line-height: $p_line;
  color:$p_color;
  text-align:$p_align;
  font-weight: $p_weight;
  @include respond_to(mob){
    font-size:$p_size_mob;
    text-align:$p_align_mob;
    line-height:$p_line_mob;
  }
  font-family:$p_font;

}

h1,h2,h3,h4,h5{
  font-family:$h_font;
  color:$h_color;
  font-weight: $h_weight;
  display:block;
  line-height:normal;
  letter-spacing: $h_space;
  /* opacity:0.87; */
}


@each $key,$value in $arr_h{
  #{$key}{font-size:$value; }
}


@each $key,$value in $arr_h_tab{
  @include respond_to(tab){
    #{$key}{font-size:$value; }
  }
}

@each $key,$value in $arr_h_mob{
  @include respond_to(mob){
    #{$key}{font-size:$value; }
  }
}
