@mixin notification-theme($my-theme-info, $my-theme-success, $my-theme-warning, $my-theme-error) {
  .success-notification-overlay,
  .info-notification-overlay,
  .warning-notification-overlay,
  .error-notification-overlay,
  .default-notification-overlay {
    border-left: 4px solid;
    &::before {
      font-family: "Material Icons", serif;
      float: left;
      font-size: 24px;
      white-space: pre;
    }
  }
  .success-notification-overlay {
    border-left-color: mat-color($my-theme-success, 400);
    &::before {
      content: "\e86c  ";
      color: mat-color($my-theme-success, 400);
    }
  }
  .info-notification-overlay{
    border-left-color: mat-color($my-theme-info, 400);
    &::before {
      content: "\e88e  ";
      color: mat-color($my-theme-info, 400);
    }
  }
  .default-notification-overlay {
    border-left-color: #fff;
    &::before {
      color: #fff;
    }
  }
  .warning-notification-overlay{
    border-left-color: mat-color($my-theme-warning, 400);
    &::before {
      color: mat-color($my-theme-warning, 400);
    }
  }
  .error-notification-overlay {
    border-left-color: mat-color($my-theme-error, 400);
    &::before {
      color: mat-color($my-theme-error, 400);
    }
  }
  .warning-notification-overlay::before {
    content: "\e002  ";
  }
  .error-notification-overlay::before {
    content: "\e000  ";
  }
}
