@import '~@angular/material/theming';
@import '../app/core/notification/notifications.scss-theme';
@include notification-theme($mat-blue, $mat-green, $mat-red, $mat-red);

@mixin my-styles-reset-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warning: map-get($theme, warn);
  $foreground: map-get($theme, foreground);

/*  a {
    color: mat-color($foreground, text);

    &:hover {
      color: mat-color($accent);
    }
  }*/

  code {
    color: mat-color($accent, lighter-contrast);
    background-color: mat-color($accent, lighter);
  }
}
